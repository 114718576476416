<template>
  <div id="home">
    <div class="home-container">
      <el-empty description="更多功能敬请期待..." :image-size="200"></el-empty>
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
#home {
  width: 100%;
  min-height: 100%;
  padding: 25px;
  background-color: var(--theme-bg-color);
  .home-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
}
</style>
